<template>
	<div class="layout">
		<main>
			<div class="layout-aside">
				<div class="layout-aside__menu">
					<ChatMenu />
				</div>

				<div
					v-if="!error"
					class="layout-aside__main layout-aside__main--p0">
					<PageChatOffer :orders="events" />

					<div class="layout-aside__slot">
						<slot />
					</div>

					<ChatManager
						v-if="isOrganizersChatEnabled()"
						:key="roomStore.getRoom?.id" />
				</div>

				<div
					v-else
					class="layout-aside__error">
					<p class="p3-b">{{ error }}</p>
				</div>

				<ModalError />
			</div>
		</main>
	</div>
</template>

<script setup lang="ts">
	import { isOrganizersChatEnabled } from "~/utils/organizers-chat-is-enable";

	defineProps<{ events: IEvent[]; error: string }>();

	const { roomStore } = useStores();
</script>
