<template>
	<CollapseMenu
		v-model="collapse"
		class="offer"
		:collapsedWidth="'90px'"
		:color="'var(--light-2)'"
		:offer="true"
		@on-change="onEventListCollapse">
		<template #header>
			<div :class="['offer-sender', { 'offer-sender--collapsed': collapse }]">
				<UserAvatar
					v-if="getActiveChat"
					:key="getActiveChat.agent"
					:fullName="getActiveChat.fio"
					:user-id="getActiveChat.agent" />

				<div class="offer-sender__content">
					<p class="p3-b offer-sender__name">{{ getActiveChat?.fio }}</p>

					<p
						v-if="getActiveChat && getLastMessage"
						class="p2 offer-sender__text">
						{{ getLastMessage.messages }}
					</p>
				</div>
			</div>
		</template>

		<template v-if="getActiveChat">
			<div
				v-if="orders.length"
				class="offer-main">
				<PageChatOfferItem
					v-for="(order, index) of orders"
					:key="order.id"
					:event-info="order"
					:isCollapse="collapse"
					:number="index + 1" />
			</div>

			<div v-else>
				<p
					v-if="!collapse"
					class="text-center offer__message mb-24">
					У вас пока нет событий по этому агенту
				</p>
			</div>

			<PageChatCreateEvent />
		</template>
	</CollapseMenu>
</template>

<script setup lang="ts">
	defineProps<{ orders: IEvent[] }>();

	const { activeChatStore, lastMessageStore } = useStores();
	const { getActiveChat } = storeToRefs(activeChatStore);
	const { getLastMessage } = storeToRefs(lastMessageStore);

	const collapse: Ref<boolean> = useState("collapse-event-list", () => true);

	const onEventListCollapse = (value: boolean) => {
		const orgChatCollapses = useState("collapse-org-chat");

		if (value && !orgChatCollapses.value) {
			orgChatCollapses.value = !orgChatCollapses.value;
		}
	};
</script>

<style lang="scss">
	@import "offer";
</style>
