<template>
	<UiButtonPlus
		:class="[{ 'button-plus--disabled': maxEventsNumber }]"
		:disabled="fetchIsPending(createEventStatus)"
		@click="createEvent" />
</template>

<script setup lang="ts">
	import { api } from "~/api/api";
	import { storeToRefs } from "pinia";
	import { fetchIsPending } from "@/utils/fetch-is-pending";

	const {
		messagesStore: { fetchMessages },
		eventsStore,
		modalErrorStore: { open: showError },
	} = useStores();

	const { getNumberEvents } = storeToRefs(eventsStore);

	const maxEventsNumber: ComputedRef<boolean> = computed((): boolean => {
		const maxEventNumber = 3;

		return getNumberEvents.value >= maxEventNumber;
	});

	const route = useRoute();

	const createEventBody: Ref<ICreateEventBody> = ref({
		agents: [`${route.params.id}`],
		event: "Входящее сообщение",
	});

	const {
		data: createData,
		error: createError,
		refresh: refreshCreateEvent,
		status: createEventStatus,
	} = await $useFetch<IApiResponsePayload<IApiResponseMessage>, TFetchError>(api.createEvent, {
		method: "POST",
		body: createEventBody,
		lazy: true,
		immediate: false,
	});

	async function createEvent(): Promise<void> {
		if (maxEventsNumber.value) {
			showError("У вас уже запущено 3 активных события, чтобы инициировать новое событие завершите одно из текущих событий");
		} else {
			await refreshCreateEvent();

			if (createError.value !== null) {
				showError(createError.value.data?.message || "", true);
			} else if (createData.value !== null) {
				await fetchMessages();
			}
		}
	}
</script>
